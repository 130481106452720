import React from 'react';
import Link, { LinkProps } from 'next/link';
import cn from '@ui/utils/cn';

type ProductCardImageLinkProps = LinkProps & {
  className?: string;
  src: string;
  alt: string;
  height: number;
  width: number;
  productHandle: string;
};

const ProductCardImageLink = ({
  className,
  src,
  alt,
  height,
  width,
  productHandle,
  ...props
}: ProductCardImageLinkProps) => {
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  return (
    <Link className={cn('block w-full relative pb-[150%]', className)} {...props} prefetch={false}>
      {/* Use full quality images for large screens */}
      <picture
        style={{
          transition: 'opacity 1s ease',
          opacity: isLoaded ? 1 : 0,
        }}
        className="size-[100%] absolute object-cover block"
      >
        <img
          src={src}
          alt={alt}
          height={height}
          width={width}
          style={{ width: '100%' }}
          loading="eager"
          fetchPriority="high"
          onLoad={() => {
            setIsLoaded(true);
          }}
          onError={(e) => {
            (e?.target as HTMLElement).closest(`[data-carousel-item="carousel-item-${productHandle}"`)?.remove();
          }}
        />
      </picture>
    </Link>
  );
};

export default ProductCardImageLink;
