import RunBlogQuery from '../../utils/RunBlogQuery.ts';
import { GET_BLOG } from '../../documents/queries/GetBlog.ts';
import { type GetBlogQuery, type GetBlogQueryVariables } from '../../__generated__/graphql.ts';

export async function GetBlogTags({ handle }: GetBlogQueryVariables) {
  let blogTags: string[] = [];
  const getBlogTagsRecursive = async ({ handle, cursor }: GetBlogQueryVariables) => {
    const { data, error } = await RunBlogQuery<GetBlogQuery>(GET_BLOG, {
      handle,
      first: 250,
      cursor: cursor || null,
    });

    if (!handle || !data?.blog || error) return;

    for (let i = 0; i < data.blog.articles.edges.length; i++) {
      blogTags = blogTags.concat(data.blog.articles.edges[i].node.tags);
    }

    const nextPageCursor = data.blog.articles.edges?.[data.blog.articles.edges.length - 1]?.cursor;

    if (!nextPageCursor) return;

    getBlogTagsRecursive({ handle, cursor: nextPageCursor });
  };

  await getBlogTagsRecursive({ handle });

  return [...new Set(blogTags)];
}
